import React from "react";
import { Link } from "gatsby";
//@ts-ignore
import * as styles from "./MegaHeader.module.css"
import { useTranslation } from "./localization";
import { Group } from "@mantine/core";

export function AppMenu() {
    const { t } = useTranslation()
    return <>
        <Group h="100%" visibleFrom="sm" ml={'auto'} gap={30}>
            <Link to="/about" className={styles.link}>
                {t('About')}
            </Link>
            <Link to="/parent" className={styles.link}>
                {t('Parent')}
            </Link>
            <Link to="/partner" className={styles.link}>
                {t('Partner')}
            </Link>
        </Group>
    </>
}