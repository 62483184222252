import React, { memo } from 'react';
import { Accordion, Anchor, Card, Group, SimpleGrid, Stack, Text } from '@mantine/core';
import { navigate } from 'gatsby';
import {
    IconUserSearch,
    IconMap,
    IconRobot,
    IconShoppingBag,
    IconTimeline,
    IconPointerDollar,
    IconVocabulary,
    IconLanguage,
    IconActivity,
    IconActivityHeartbeat,
    IconClockPlay,
    IconChartHistogram,
    IconChartDots,
    IconClockShare,
    IconPlayerPlay,
    IconDoorExit,
    IconRobotFace,
    IconTableOptions,
    IconAbc,
    IconMessage2Question,
    IconSettings,
    IconMailbox,
    IconBrain,
    IconMessageLanguage,
    IconClipboard,
    IconFolder,
    IconCalendarEvent,
    IconMeteor,
    IconNumber,
    IconUsersGroup,
    IconBrandTeams,
    IconPackage,
    IconTicket,
    IconUserDollar,
    IconShoppingCart,
    IconHistory,
    IconFileExcel,
    IconAdjustments,
    IconBooks,
    IconOlympics,
    IconTournament,
    IconList,
    IconTable,
    IconProps,
    Icon,
    IconDeviceGamepad,
    IconSchool,
    IconSchoolBell
} from '@tabler/icons-react';
import { RealmRole, Role } from './users/constants';
import { useCheckRoles } from '../context/AuthProvider';
import { useTranslation } from '../components/localization';

const menu = [
    { title: 'Maps', to: '/manage/maps', icon: IconMap, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'NPC', to: '/manage/npc', icon: IconRobot, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Subcriptions', to: '/manage/subcriptions', icon: IconPointerDollar, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Items', to: '/manage/items', icon: IconShoppingBag, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Avatar Frame', to: '/manage/avatar-frame', icon: IconShoppingBag, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Bots', to: '/manage/bots', icon: IconRobotFace, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Users', to: '/manage/users', icon: IconUserSearch, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Roles', to: '/manage/roles', icon: IconUsersGroup, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Locaiization', to: '/manage/locales', icon: IconLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Locale Props', to: '/manage/locales-props', icon: IconMessageLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Achievement', to: '/manage/achievement', icon: IconLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Level Mapping', to: '/manage/level-mapping', icon: IconLanguage, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Events', to: '/manage/events', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Maintenance', to: '/manage/maintenance', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Title', to: '/manage/title', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Block Words', to: '/manage/block-word', icon: IconCalendarEvent, roles: [Role.Admin, RealmRole.Admin] },
]

const contentMenu = [
    { title: 'Banks', to: '/manage/banks', icon: IconFolder, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Dictionary', to: '/manage/dictionary', icon: IconVocabulary, roles: [RealmRole.Admin, Role.Editor] },
    { title: 'Classes', to: '/manage/classes', icon: IconClipboard, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Learns', to: '/manage/learns', icon: IconAbc, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Reports', to: '/report/report-learning', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Reports classes', to: '/report/report-classes', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
]

const reportMenu = [
    { title: 'Total Users', to: '/report/total', icon: IconNumber, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Total Users*', to: '/report/total-user-with-blocks', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Active Users', to: '/report/active', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Active Users*', to: '/report/active-with-blocks', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Sessions', to: '/report/sessions', icon: IconClockShare, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Sessions*', to: '/report/sessions-with-blocks', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Churn Rate', to: '/report/churnrate', icon: IconDoorExit, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Activities', to: '/manage/activities', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Playing Time', to: '/report/active/?tab=playing', icon: IconPlayerPlay, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Retention', to: '/report/retention', icon: IconClockPlay, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Stickiness', to: '/report/stickiness', icon: IconChartDots, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Realtime', to: '/report/realtime', icon: IconTimeline, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'RealtimeTable', to: '/report/realtime-table', icon: IconTimeline, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Max CCU', to: '/report/ccu', icon: IconMeteor, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Rooms', to: '/report/brainwar', icon: IconBrain, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Load Time', to: '/report/load-time', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Player', to: '/report/player', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Monetization', to: '/report/monetization', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Arena', to: '/report/arena', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'First Session', to: '/report/first-session', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'New Users*', to: '/report/new-user', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'Summary', to: '/report/summary', icon: IconTimeline, roles: [Role.Admin, RealmRole.Admin], color: 'red' },
    { title: 'Weekly Sessions', to: '/report/sessions-weekly', icon: IconClockShare, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Weekly New Users', to: '/report/new-users-weekly', icon: IconClockShare, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Monthly ARPU', to: '/report/monthly-arpu', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
]

const userActivitieMenu = [
    { title: 'IAP Report', to: '/report/subcription-sale-group-source', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'IAP Table', to: '/manage/user-subcription-source', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin], color: 'orange' },
    { title: 'User Activities', to: '/manage/user-activities', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Count', to: '/report/user-count', icon: IconActivityHeartbeat, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Delete', to: '/report/user-delete', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Checkin', to: '/report/user-checkin-daily', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Gold', to: '/report/user-gold', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Exp', to: '/report/user-exp', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User Play Game', to: '/report/user-play-game-daily', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    // { title: 'User Play', to: '/report/user-play-game', icon: IconChartHistogram, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Subcription', to: '/report/subcription-sale', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'User buy over one ', to: '/manage/user-buy-over-one-subscription', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Bought Items', to: '/report/user-bought-items', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Use Items', to: '/report/user-use-items', icon: IconActivity, roles: [Role.Admin, RealmRole.Admin] },
]

const gameMenu = [
    { title: 'Games', to: '/manage/games', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Quests', to: '/manage/quests', icon: IconMessage2Question, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Mailbox', to: '/manage/mailbox', icon: IconMailbox, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Popups', to: '/manage/popup-notification', icon: IconMailbox, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Lands', to: '/manage/config-land-item', icon: IconSettings, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'System', to: '/manage/configs', icon: IconSettings, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Voucher', to: '/manage/vouchers', icon: IconShoppingBag, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Pet Config', to: '/manage/pet-config', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Reward Online', to: '/manage/reward-online', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
    { title: 'Resource Config', to: '/manage/resource', icon: IconTableOptions, roles: [Role.Admin, RealmRole.Admin] },
]

const salesMenu = [
    { title: 'Teams', to: '/sales/teams', icon: IconBrandTeams, roles: [RealmRole.Admin, RealmRole.SalesManager, Role.SalesManager] },
    { title: 'Packages', to: '/sales/packages', icon: IconPackage, roles: [Role.SalesMember, RealmRole.SalesMember] },
    { title: 'Orders', to: '/sales/orders', icon: IconPackage, roles: [RealmRole.Admin, RealmRole.SalesMember, RealmRole.SalesManager, Role.SalesMember, Role.SalesManager] },
    { title: 'Codes', to: '/sales/codes', icon: IconTicket, roles: [Role.SalesMember] },
    { title: 'Sellers', to: '/sales/sellers', icon: IconUserDollar, roles: [RealmRole.Admin, RealmRole.SalesManager, Role.SalesManager] },
]

const schoolMenu = [
    { title: 'All Classes', to: '/schools/classes', icon: IconSchoolBell, roles: [RealmRole.Admin] },
    { title: 'Your Classes', to: '/schools/classes', icon: IconBrandTeams, roles: [RealmRole.Teacher, Role.Teacher] },
]

const tournamentMenu = [
    { title: 'Events', to: '/tournaments/events', icon: IconCalendarEvent, roles: [RealmRole.Admin, Role.Admin] },
    { title: 'Matches', to: '/tournaments/matches', icon: IconTable, roles: [RealmRole.Admin, Role.Admin] },
]

function Shortcut({ data, toggle }: any) {
    const { title, to, roles, icon, color } = data
    const right = useCheckRoles(roles)

    if (!right) return <></>

    const Icon = icon;

    return <Anchor key={title} c={color ?? 'blue'} onClick={_ => {
        navigate(to)
        toggle && toggle()
    }}>
        <Group align='center' gap={'xs'}><Icon size={16} /><Text>{title}</Text></Group>
    </Anchor>
}

function ShortcutGroup({ children, title, icon }: { children: React.ReactNode, title: string, icon?: React.ReactNode }) {
    const { t } = useTranslation()

    return <Accordion.Item key={title} value={title.toLocaleLowerCase()}>
        <Accordion.Control icon={icon}>
            <Text fw={500}>
                {t(title)}
            </Text>
        </Accordion.Control>
        <Accordion.Panel>
            <Stack ml={5}>
                {children}
            </Stack>
        </Accordion.Panel>
    </Accordion.Item>
}

let openedMenu: string | null = ''
export const Shortcuts = memo(function ({ toggle, location }: any) {
    const { t } = useTranslation()
    const su = useCheckRoles([RealmRole.Admin, Role.Editor])
    const sm = useCheckRoles([RealmRole.Admin, RealmRole.SalesManager, RealmRole.SalesMember, Role.SalesMember, Role.SalesManager])
    const te = useCheckRoles([RealmRole.Admin, Role.Teacher])

    if (location && location.pathname) {
        const paths = location.pathname.split('/')
        if (paths && paths.length > 1) {
            openedMenu = paths[1]
        }
    }

    const manage = menu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const content = contentMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const games = gameMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const reports = reportMenu.map((x, idx) => <Shortcut data={x} key={idx} toggle={toggle} />)
    const userActivities = userActivitieMenu.map((x, idx) => <Shortcut data={x} key={idx} toggle={toggle} />)
    const tournaments = tournamentMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const sales = salesMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)
    const school = schoolMenu.map(x => <Shortcut data={x} key={x.title} toggle={toggle} />)

    return <>
        <Accordion defaultValue={openedMenu} onChange={x => { openedMenu = x }}>
            {su && <ShortcutGroup title={'Content'} icon={<IconBooks size={22} />}>{content}</ShortcutGroup>}
            {su && <ShortcutGroup title={'Manage'} icon={<IconSettings size={22} />}>{manage}</ShortcutGroup>}
            {su && <ShortcutGroup title={'Games'} icon={<IconDeviceGamepad size={22} />}>{games}</ShortcutGroup>}
            {su && <ShortcutGroup title={'Activities'} icon={<IconActivity size={22} />}>{userActivities}</ShortcutGroup>}
            {(su) && <ShortcutGroup title={'Tournament'} icon={<IconCalendarEvent size={22} />}>{tournaments}</ShortcutGroup>}
            {(sm) && <ShortcutGroup title={'Sales'} icon={<IconPackage size={22} />}>{sales}</ShortcutGroup>}
            {te && <ShortcutGroup title={'Schools'} icon={<IconSchool size={22} />}>{school}</ShortcutGroup>}
            {su && <ShortcutGroup title={'Reports'} icon={<IconChartHistogram size={22} />}>{reports}</ShortcutGroup>}
        </Accordion>
    </>
})

